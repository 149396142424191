import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { LocalizationProvider } from '@material-ui/pickers';
import LuxonUtils from '@material-ui/pickers/adapter/luxon';
import { SnackbarProvider } from 'notistack';

import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

let darkTheme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    type: 'dark',
    background: {
      default: '#283743',
      paper: '#31424f',
    },
  },
});

darkTheme = responsiveFontSizes(darkTheme, {
  factor: 6,
});

ReactDOM.render(
  <React.StrictMode>
    <>
      <BrowserRouter>
        <MuiThemeProvider theme={darkTheme}>
          <ThemeProvider theme={darkTheme}>
            <LocalizationProvider dateAdapter={LuxonUtils} locale="th">
              <>
                <CssBaseline />
                <SnackbarProvider>
                  <App />
                </SnackbarProvider>
              </>
            </LocalizationProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
