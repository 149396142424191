import React from 'react';
import { Paper as MuiPaper, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { DateTime, Settings } from 'luxon';
import { logoPath } from '../../utils/mapping';
import { GasStationLogo } from '../utils';

const Paper = styled(MuiPaper)`
  overflow: hidden;
  height: 64px;
  display: flex;
`;

const Map = styled.img`
  background-color: #fff;
  width: 64px;
  height: 64px;
`;

const DataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 5px 10px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Amount = styled.div`
  margin-left: 8px;
  min-width: 70px;
`;

const Font10 = styled(Typography).attrs({
  component: 'span',
})`
  display: block;
  font-size: 0.6875rem;
  line-height: 1.25;
  text-transform: uppercase;
`;

const Font12 = styled(Typography).attrs({
  component: 'span',
})`
  display: block;
  font-size: 0.75rem;
  line-height: 1.25;
  text-transform: uppercase;
`;

Settings.defaultLocale = 'th';
Settings.defaultOutputCalendar = 'buddhist';

const Record = ({ data }) => {
  const dt = DateTime.fromMillis(data.date).toFormat('dd LLL yyyy HH:mm');
  const currentMileage = data.mileage.current.toLocaleString();
  const diffMileage = (
    data.mileage.current - data.mileage.previous
  ).toLocaleString();
  const amount = data.paidAmount.toLocaleString('th', {
    style: 'currency',
    currency: 'THB',
  });

  return (
    <Grid item xs={12}>
      <Paper elevation={0}>
        <Map
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.location.latitude},${data.location.longitude}&zoom=15&size=64x64&scale=2&markers=size:tiny|${data.location.latitude},${data.location.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
        />
        <DataContainer>
          <Left>
            <Font12>{dt}</Font12>
            <div>
              <Font10>{currentMileage} KM</Font10>
              <Font12>
                <strong>{diffMileage} KM</strong>
              </Font12>
            </div>
          </Left>
          <Right>
            <GasStationLogo
              src={`${logoPath}/logo-${data.gasStation}.png`}
              alt={data.gasStation}
            />
            <Amount>
              <Typography variant="subtitle2" align="right">
                <strong>{amount}</strong>
              </Typography>
            </Amount>
          </Right>
        </DataContainer>
      </Paper>
    </Grid>
  );
};

export default Record;
