import React from 'react';

import GoogleLogin from '../google-login';
import { signInWithGoogle } from '../../../firebase';

const Login = () => {
  return (
    <GoogleLogin
      signIn={signInWithGoogle}
      theme="dark"
      buttonText="Sign in with Google"
    />
  );
};

export default Login;
