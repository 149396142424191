import React, { useState, useEffect } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';

import { useAuthDataContext } from '../../context/AuthContext';
import { Records } from '../../components/Record';
import { Header, Footer, Wrapper } from '../../components/Dashboard';
import { getRecords, listenToRecords } from '../../firestore';

const Dashboard = () => {
  const { user } = useAuthDataContext();
  const [records, setRecords] = useState(null);

  const refresh = () => {
    getRecords(user, setRecords);
  };

  useEffect(() => {
    const unsubscribe = listenToRecords(user, setRecords);
    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <Wrapper>
      <Header
        user={user}
        title="History"
        icon={<RefreshIcon />}
        iconAction={refresh}
      />
      {records && <Records records={records} />}
      <Footer />
    </Wrapper>
  );
};

export default Dashboard;
