import React from 'react';
import {
  Container as MuiContainer,
  Grid,
  Typography,
  // Divider,
} from '@material-ui/core';
import styled from 'styled-components';

import { useAuthDataContext } from '../../context/AuthContext';
import {
  GoogleLogin,
  GoogleLogout,
  GoogleBtnWrapper,
} from '../../components/GoogleLogin';
// import InitialForm from '../../components/InitialForm';
import { Redirect } from 'react-router-dom';

const Container = styled(MuiContainer)`
  display: flex;
  align-items: center;
  min-height: 80vh;
`;

const Initial = () => {
  const { user } = useAuthDataContext();

  if (user) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Container>
      <Grid container alignItems="center" justifyContent="center" spacing={4}>
        <Grid item>
          <Typography variant="h1" component="h1" align="center" paragraph>
            <strong>Fuel Tracker</strong>
          </Typography>
          <GoogleBtnWrapper>
            {user ? <GoogleLogout /> : <GoogleLogin />}
          </GoogleBtnWrapper>
        </Grid>
        {/* <Grid item xs={8}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h2" component="h2" align="center" paragraph>
            <strong>Setup</strong>
          </Typography>
          <InitialForm />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default Initial;
