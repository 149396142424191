import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { MountTransition } from '../AnimatedRoute/MountTransition';
import { useAuthDataContext } from '../../context/AuthContext';

export const PrivateRoute = ({ children, exact = false, path, ...options }) => {
  const { user } = useAuthDataContext();
  return (
    <Route exact={exact} {...options}>
      <MountTransition>
        {user ? children : <Redirect to="/" from={path} />}
      </MountTransition>
    </Route>
  );
};

// const PrivateRoute = ({
//   children,
//   exact = false,
//   path,
//   slide = 0,
//   slideUp = 0,
//   ...rest
// }) => {
//   const { user } = useAuthDataContext();

//   return (
//     // <RouteTransition
//     //   {...rest}
//     //   render={(props) =>
//     //     isAuthenticated ? (
//     //       <Component {...props} />
//     //     ) : (
//     //       <Redirect to={{ pathname: '/', state: { from: props.location } }} />
//     //     )
//     //   }
//     // />
//     <Route
//       exact={exact}
//       {...rest}
//       render={(props) => {
//         console.log(props);
//         return user ? (
//           <MountTransition slide={slide} slideUp={slideUp}>
//             {children}
//           </MountTransition>
//         ) : (
//           <Redirect to={{ pathname: '/', state: { from: props.location } }} />
//         );
//       }}
//     />
//   );
// };

export default PrivateRoute;
