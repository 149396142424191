import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: 'fuel.jayakornk.dev',
  databaseURL: 'https://fuel-tracker-288012.firebaseio.com',
  projectId: 'fuel-tracker-288012',
  storageBucket: 'fuel-tracker-288012.appspot.com',
  messagingSenderId: '915126076474',
  appId: '1:915126076474:web:13ebde06d44f4709a2e103',
  measurementId: 'G-74DDX27G7C',
};
const provider = new firebase.auth.GoogleAuthProvider();

firebase.initializeApp(firebaseConfig);

firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code === 'failed-precondition') {
      console.log(
        'Multiple tabs open, persistence can only be enabled in one tab at a a time.'
      );
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      console.log(
        'The current browser does not support all of the features required to enable persistence'
      );
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

// Authentication
export const auth = firebase.auth();
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};
export const signOut = () => {
  auth.signOut();
};

// Firestore
export const firestore = firebase.firestore();
