import React, { useState, useEffect, useCallback } from 'react';
import * as serviceWorker from './serviceWorker';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';

// import { withRouter } from 'react-router-dom';

import { Initial, Dashboard, Add } from './pages';
import { AnimatedRoutes, RouteTransition } from './components/AnimatedRoute';
// import Private from './components/utils/private';
import AuthProvider from './context/AuthContext';
import PrivateRoute from './components/utils/private';

const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState({});

  const onServiceWorkerUpdate = (registration) => {
    setWaitingWorker(registration && registration.waiting);
    setNewVersionAvailable(true);
  };

  const updateServiceWorker = useCallback(() => {
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setNewVersionAvailable(false);
    window.location.reload();
  }, [waitingWorker]);

  const refreshAction = useCallback(
    (key) => {
      //render the snackbar button
      return (
        <>
          <Button
            className="snackbar-button"
            size="small"
            onClick={updateServiceWorker}
            variant="text"
          >
            refresh
          </Button>
        </>
      );
    },
    [updateServiceWorker]
  );

  useEffect(() => {
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });

    if (newVersionAvailable) {
      //show snackbar with refresh button
      enqueueSnackbar('A new version was released', {
        persist: true,
        variant: 'success',
        action: refreshAction(),
      });
    }
  }, [
    enqueueSnackbar,
    newVersionAvailable,
    refreshAction,
    updateServiceWorker,
  ]);

  return (
    <AuthProvider>
      <AnimatedRoutes exitBeforeEnter initial={false}>
        <RouteTransition path="/" exact>
          <Initial />
        </RouteTransition>
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/record/add">
          <Add />
        </PrivateRoute>
      </AnimatedRoutes>
    </AuthProvider>
  );
};

export default App;

// https://medium.com/@subwaymatch/disabling-back-button-in-react-with-react-router-v5-34bb316c99d7
// class App extends React.Component {
//   constructor(props) {
//     super(props);

//     // Store the previous pathname and search strings
//     this.currentPathname = null;
//     this.currentSearch = null;
//   }

//   componentDidMount() {
//     const { history } = this.props;

//     history.listen((newLocation, action) => {
//       if (action === 'PUSH') {
//         if (
//           newLocation.pathname !== this.currentPathname ||
//           newLocation.search !== this.currentSearch
//         ) {
//           // Save new location
//           this.currentPathname = newLocation.pathname;
//           this.currentSearch = newLocation.search;

//           // Clone location object and push it to history
//           history.push({
//             pathname: newLocation.pathname,
//             search: newLocation.search,
//           });
//         }
//       } else {
//         // Send user back if they try to navigate back
//         history.go(1);
//       }
//     });
//   }

//   render() {
//     return (
//       <AnimatedRoutes exitBeforeEnter initial={false}>
//         <RouteTransition path="/" exact>
//           <Initial />
//         </RouteTransition>
//         <RouteTransition path="/dashboard">
//           <Dashboard />
//         </RouteTransition>
//         <RouteTransition path="/record/add">
//           <Add />
//         </RouteTransition>
//       </AnimatedRoutes>
//     );
//   }
// }

// export default withRouter(App);
