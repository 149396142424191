import React from 'react';
import { AppBar as MuiAppBar, Fab, Toolbar } from '@material-ui/core';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const AppBar = styled(MuiAppBar)`
  top: auto;
  bottom: 0;
  width: 100%;
  text-align: center;
  ${({ theme }) => `
    background-color: ${theme.palette.background.default};
  `}
`;

const CircleButton = styled(Fab)`
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
`;

const Footer = () => {
  const history = useHistory();
  const toAddPage = () => {
    history.push('/record/add');
  };
  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <CircleButton color="primary" onClick={toAddPage}>
            <LocalGasStationIcon />
          </CircleButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Footer;
