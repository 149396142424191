import React from 'react';
import {
  Container,
  MenuItem,
  TextField,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { Formik, Form, Field, useFormikContext } from 'formik';
import styled from 'styled-components';
import axios from 'axios';
import parser from 'fast-xml-parser';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { usePosition } from 'use-position';

import { ButtonCenter } from '../utils';
import { gasStations, logoPath, oilTypes } from '../../utils/mapping';
import { addRecord } from '../../firestore';
import { useHistory } from 'react-router-dom';
import GoogleMap from './google-map';

const GasStationLogo = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
`;

const RefuelDate = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <DateTimePicker
      id="date-picker-dialog"
      label="Date"
      renderInput={(props) => (
        <TextField variant="outlined" margin="normal" fullWidth {...props} />
      )}
      inputFormat="dd LLL yyyy HH:mm"
      toolbarFormat="d LLLL"
      value={values.date}
      onChange={(value) => setFieldValue('date', value)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  );
};

const getOilPrices = async () => {
  const { data } = await axios.get('https://bangchak.co.th/api/oilprice');
  const oilPrices = parser
    .parse(data)
    .header.item.reduce(function (acc, cur, i) {
      acc[oilTypes[i].value] = cur;
      return acc;
    }, {});

  return oilPrices;
};

const calcFilledOil = (values, data) => {
  let amount = values.paidAmount / data[values.oilType].today;
  amount = parseFloat(amount.toFixed(3));

  return amount;
};

const AddRecordSchema = Yup.object().shape({
  date: Yup.date(),
  gasStation: Yup.string().required('Required'),
  oilType: Yup.string().required('Required'),
  paidAmount: Yup.number()
    .min(0, 'Please enter positive value')
    .integer('Please enter integer')
    .required('Required'),
  mileage: Yup.number()
    .min(0, 'Please enter positive value')
    .integer('Please enter integer')
    .required('Required'),
});

const initialValues = {
  date: DateTime.local(),
  gasStation: '',
  oilType: '',
  paidAmount: '',
  mileage: '',
};

const RecordAdd = ({ user }) => {
  const history = useHistory();
  const { data: oilPrices, status } = useQuery('oilPrices', getOilPrices, {
    staleTime: 600000,
    cacheTime: 900000,
  });
  const pos = usePosition(false, { enableHighAccuracy: true });

  const onSubmit = (values, actions) => {
    actions.setSubmitting(false);

    // ...values,
    const data = {
      date: values.date.toMillis(),
      gasStation: values.gasStation,
      location: {
        ...pos,
      },
      mileage: {
        current: values.mileage,
      },
      oil: {
        amount: calcFilledOil(values, oilPrices),
        price: oilPrices[values.oilType].today,
        type: values.oilType,
      },
      paidAmount: values.paidAmount,
    };

    addRecord(user, data);
    history.push('/dashboard');
  };

  return (
    <>
      {pos && (
        <GoogleMap
          lat={pos.latitude}
          lng={pos.longitude}
          height="200px"
          width="100%"
          error={pos.error}
        />
      )}
      <Container style={{ marginTop: '1rem' }}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={AddRecordSchema}
        >
          {({ values, errors, touched, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <RefuelDate />
                <Field
                  as={TextField}
                  id="outlined-select-gas-station"
                  select
                  label="Gas Station"
                  name="gasStation"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={errors.gasStation && touched.gasStation ? true : false}
                  helperText={
                    errors.gasStation && touched.gasStation && errors.gasStation
                  }
                >
                  {gasStations.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <ListItemIcon>
                        <GasStationLogo
                          src={`${logoPath}/logo-${option.value}.png`}
                          alt={option.label}
                        />
                      </ListItemIcon>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  as={TextField}
                  id="outlined-select-oil-type"
                  select
                  label="Oil Type"
                  name="oilType"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={errors.oilType && touched.oilType ? true : false}
                  helperText={
                    errors.oilType && touched.oilType && errors.oilType
                  }
                >
                  {oilTypes.map((option, index) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  as={TextField}
                  id="outlined-paidAmount"
                  label="Paid"
                  name="paidAmount"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                  error={errors.paidAmount && touched.paidAmount ? true : false}
                  helperText={
                    errors.paidAmount && touched.paidAmount && errors.paidAmount
                  }
                />
                {values.oilType && values.paidAmount && status === 'success' && (
                  <div>
                    <Typography variant="inherit">
                      เติมทั้งหมด: {calcFilledOil(values, oilPrices)} ลิตร
                    </Typography>
                    <Typography variant="inherit">
                      ราคาน้ำมัน:{' '}
                      {`${oilPrices[values.oilType].today} ${
                        oilPrices[values.oilType].unit_th
                      }`}
                    </Typography>
                  </div>
                )}
                <Field
                  as={TextField}
                  id="outlined-mileage"
                  label="Current Mileage"
                  name="mileage"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                  error={errors.mileage && touched.mileage ? true : false}
                  helperText={
                    errors.mileage && touched.mileage && errors.mileage
                  }
                />
                <ButtonCenter
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!(isValid && dirty && pos)}
                >
                  Add
                </ButtonCenter>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
};

export default RecordAdd;
