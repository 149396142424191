import firebase from 'firebase/app';
import { firestore as db } from '../firebase';

export const listenToRecords = (user, callback) => {
  const recordsRef = db
    .collection('users')
    .doc(user ? user.uid : '0')
    .collection('records');
  const unsubscribe = recordsRef
    .orderBy('date', 'desc')
    .onSnapshot((snapshot) => {
      const records = snapshot.docs.map((doc) => {
        const data = doc.data();

        return {
          id: doc.id,
          ...data,
          date: data.date.toMillis(),
        };
      });

      callback(records);
    });

  return unsubscribe;
};

export const getRecords = async (user, callback) => {
  const recordsRef = db
    .collection('users')
    .doc(user ? user.uid : '0')
    .collection('records');
  recordsRef
    .orderBy('date', 'desc')
    .get()
    .then((snapshot) => {
      const records = snapshot.docs.map((doc) => {
        const data = doc.data();

        return {
          id: doc.id,
          ...data,
          date: data.date.toMillis(),
        };
      });

      callback(records);
    });
};

export const addRecord = async (user, data, callback) => {
  const usersRef = db.collection('users');

  const u = await usersRef.doc(user.uid);

  // Init user
  let userData = u.get();
  if (!userData.exists) {
    userData = await usersRef.doc(user.uid).set({
      email: user.email,
    });
  }

  // Get previous mileage
  const recRef = u.collection('records');
  const prevRecord = await recRef.orderBy('date', 'desc').limit(1).get();
  const prevMileage = prevRecord.empty
    ? 0
    : prevRecord.docs[0].data().mileage.current;
  data.mileage['previous'] = prevMileage;

  // Add new record
  recRef.add({
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    ...data,
    date: firebase.firestore.Timestamp.fromMillis(data.date),
  });
};
