import React from 'react';
import {
  AppBar as MuiAppBar,
  Typography,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from '@material-ui/core';
import styled from 'styled-components';
import { signOut } from '../../firebase';
import { useHistory } from 'react-router-dom';

const AppBar = styled(MuiAppBar)`
  ${({ theme }) => `
    background-color: ${theme.palette.background.paper};
  `}

  h1 {
    font-size: 1.5rem;
  }
`;

const Title = styled(Typography)`
  flex-grow: 1;
`;

const Header = ({ user, title, icon, iconAction, isBack }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    history.push('/');
    signOut();
    handleClose();
  };
  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={iconAction}
            disableRipple={isBack}
          >
            {icon}
          </IconButton>
          <Title variant="h2" component="h1" align="center">
            <strong>{title}</strong>
          </Title>
          {user ? (
            <>
              <Avatar
                alt={user.displayName}
                src={user.photoURL}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              />
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>
                  <Typography variant="inherit" noWrap>
                    Profile
                  </Typography>
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Typography variant="inherit" noWrap>
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Avatar style={{ opacity: 0 }} />
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Header;
