export default (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
      };
    case 'PENDING':
      return {
        ...state,
        pending: false,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: {},
      };

    default:
      return state;
  }
};
