import styled from 'styled-components';

const GoogleBtnWrapper = styled.div`
  ${({ theme }) => `
    margin: ${theme.spacing(4)}px auto;
  `}
  text-align: center;
`;

export default GoogleBtnWrapper;
