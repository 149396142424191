import { Button } from '@material-ui/core';
import styled from 'styled-components';

const ButtonCenter = styled(Button)`
  display: block;
  ${({ theme }) => `
    margin: ${theme.spacing(2)}px auto 0;
  `}
`;

export default ButtonCenter;
