import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from '@material-ui/core';

const MapMarker = () => {
  return (
    <LocationOnIcon style={{ transform: 'translateY(-100%)' }} color="error" />
  );
};

const Placeholder = styled.div`
  position: relative;

  > p {
    position: absolute;
    top: 50%;
    left: 16px;
    right: 16px;
    transform: translateY(-50%);
    text-align: center;
  }
`;

const GoogleMap = ({ lat, lng, height, width, error }) => {
  return lat && lng ? (
    <div style={{ height: height, width: width }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY,
        }}
        defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={17}
        yesIWantToUseGoogleMapApiInternals
      >
        <MapMarker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  ) : (
    <Placeholder>
      <Skeleton variant="rectangular" width={width} height={height} />
      {error && <Typography variant="inherit">{error}</Typography>}
    </Placeholder>
  );
};

export default GoogleMap;
