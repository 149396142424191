import React from 'react';
import { Container, Grid as MuiGrid } from '@material-ui/core';
import styled from 'styled-components';

import Record from './record';

const Grid = styled(MuiGrid)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)}px 0;
  `}
`;

const Records = ({ records }) => {
  return (
    <Container>
      <Grid container spacing={2}>
        {records.map((record) => (
          <Record key={record.id} data={record} />
        ))}
      </Grid>
    </Container>
  );
};

export default Records;
