import React from 'react';

import GoogleLogout from '../google-logout';
import { signOut } from '../../../firebase';

const Logout = () => {
  return <GoogleLogout theme="dark" signOut={signOut} />;
};

export default Logout;
