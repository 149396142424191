import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';

import { useAuthDataContext } from '../../context/AuthContext';
import { Header, Wrapper, RecordAdd } from '../../components/Dashboard';

const Add = () => {
  const { user } = useAuthDataContext();
  const history = useHistory();

  const back = () => {
    history.push('/dashboard');
  };
  return (
    <Wrapper style={{ paddingBottom: '16px' }}>
      <Header
        title="Add Record"
        icon={<ArrowBackIosIcon />}
        iconAction={back}
        isBack
      />
      <RecordAdd user={user} />
    </Wrapper>
  );
};

export default Add;
