import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Lottie from 'react-lottie';

import AppReducer from './AuthReducer';
import { auth } from '../../firebase';
import * as loading from '../../assets/loading.json';

const initialState = {
  user: null,
  pending: true,
};

export const AuthContext = createContext({});

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const loadingStyle = {
  backgroundColor: '#31424f',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      login(userAuth);
      dispatch({
        type: 'PENDING',
      });
    });
  }, []);

  function login(user) {
    dispatch({
      type: 'LOGIN',
      payload: user,
    });
  }

  function logout() {
    dispatch({
      type: 'LOGOUT',
    });
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {state.pending ? (
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={loadingStyle}
        >
          <Lottie options={defaultOptions} height={140} width={140} />
        </motion.div>
      ) : (
        <AuthContext.Provider
          value={{
            user: state.user,
            login,
            logout,
          }}
        >
          <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {children}
          </motion.div>
        </AuthContext.Provider>
      )}
    </AnimatePresence>
  );
};

export const useAuthDataContext = () => useContext(AuthContext);

export default AuthProvider;
