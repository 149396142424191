export const logoPath = '/images/gas-station-logos';

export const gasStations = [
  {
    value: 'ptt',
    label: 'PTT',
  },
  {
    value: 'bangchak',
    label: 'Bangchak',
  },
  {
    value: 'shell',
    label: 'Shell',
  },
  {
    value: 'esso',
    label: 'Esso',
  },
  {
    value: 'caltex',
    label: 'Caltex',
  },
  {
    value: 'pt',
    label: 'PT',
  },
];

export const oilTypes = [
  {
    value: 'Diesel +',
    label: 'Diesel +',
  },
  {
    value: 'Diesel',
    label: 'Diesel',
  },
  {
    value: 'Diesel B10',
    label: 'Diesel B10',
  },
  {
    value: 'Diesel B20',
    label: 'Diesel B20',
  },
  {
    value: 'GS E85',
    label: 'GS E85',
  },
  {
    value: 'GS E20',
    label: 'GS E20',
  },
  {
    value: 'GS 91',
    label: 'GS 91',
  },
  {
    value: 'GS 95',
    label: 'GS 95',
  },
  {
    value: 'NGV',
    label: 'NGV',
  },
];
